import * as React from "react";
import {graphql, PageProps} from "gatsby";

import Layout from "../components/layout";
import Hero from "../components/hero";
import HeroContentHeading from "../components/hero-content-heading";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import ArticlesGridShort from "../components/articles-grid-short";
import Section from "../components/section";
import LegalPackageGrid from "../components/legal-package-grid";
import TemplateHead from "../components/template-head";

import "../styles/page-services.scss";

type DataProps = {
  strapiAdvisor: any,
  lpaPageData: any,
  willPageData: any,
}

export default function LegalServices({data: {strapiAdvisor, willPageData, lpaPageData}}: PageProps<DataProps>) {
  const advisor = strapiAdvisor

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--funeral pl-4 pr-4"
        bgImageClassName="hero-image hero-image--funeral hero-image--index"
        contentClassName="hero-content hero-content--funeral hero-content--index"
        altImages="Legal Services Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[
          {name: "Fenix Home", slug: "/"},
          {name: "Legal Services", slug: "/legal-services/"},
        ]}
      >
        <HeroContentHeading
          headingTitle="Legal Services"
          headingContent="No matter what challenging and complex moment comes your way, our compassionate and knowledgeable team is here to help and give you peace of mind."
          advisorData={advisor}
        />
      </Hero>
      <Section
        sectionClass={"section-container package-section package-section--index pl-4 pr-4"}
      >
        <LegalPackageGrid/>
      </Section>
      <Section
        sectionClass={"section-container pl-4 pr-4"}
      >
        <div className="quote-container quote-container--title content-container">
          <div className="quote-wrapper quote-wrapper--title">
            <h2 className="text-white">Absolute transparency on fees and prices.</h2>
            <p className="text-white">We’ll never put pressure on you. We’ll never hide fees in the small print. We know
              how complex these moments can be, which is why we’re absolutely committed to the highest standards of
              honesty and openness.</p>
          </div>
          <img
            src="/images/stripe_1-yellow.svg"
            alt="Fenix Funeral Directors"
            className="stripe stripe--1 stripe--services d-none d-lg-block"
          />
        </div>
      </Section>
      <ArticlesGridShort/>
      <TrustpilotCarousel/>
    </Layout>
  );
}

export function Head() {
  return (
    <TemplateHead
      title="Legal Services - Fenix Funeral Directors"
      shortlink="https://fenixfuneral.co.uk/legal-services/"
      alternate="https://fenixfuneral.co.uk/legal-services/"
      canonical="https://fenixfuneral.co.uk/legal-services/"
      og={{
        url: "https://fenixfuneral.co.uk/legal-services/",
        title: "Legal Services - Fenix Funeral Directors",
      }}
      ldjsonbreadcrumb={`{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement":
        [{
        "@type": "ListItem",
        "position": "1",
        "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
        },
        {
        "@type": "ListItem",
        "position": "2",
        "name": "Legal Services","item": "https://fenixfuneral.co.uk/legal-services/"
        }]
        }`
      }
    />
  );
}

export const pageQuery = graphql`
    query {
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 400, 
                            height: 400, 
                            placeholder: NONE,
                            outputPixelDensities: 1
                        )
                    }
                }
            }
        }
    }
`;