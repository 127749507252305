import * as React from "react";
import {useEffect, useState} from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

import CleanDataParser from "../helpers/editorjs-parser";
import ReadMore from "./read-more";

export default function ArticlesGridShort(props: any) {
  const {allStrapiArticle} =
  useStaticQuery(graphql`
      query {
          allStrapiArticle {
              nodes {
                  id
                  viewCounter
                  urlPart
                  titleShort
                  title
                  parentCategory
                  parentCategorySlug
                  shortContent {
                      data {
                          id
                          shortContent
                      }
                  }
                  coverImage {
                      alternativeText
                      id
                      localFile {
                          childImageSharp {
                              gatsbyImageData(
                                  placeholder: NONE,
                                  width: 700,
                                  height: 600,
                                  outputPixelDensities: 1,
                                  transformOptions: {cropFocus: CENTER, fit: COVER},
                              )
                          }
                          url
                      }
                  }
                  article_categories {
                      urlPart
                  }
              }
          }
      }
  `);

  const [articles, setArticles] = useState<any>([]);
  const firstItem = [0];
  useEffect(() => {
    setArticles(allStrapiArticle.nodes);
  }, []);

  let articlesList = props.locationTypeSlug === "crematoriums" ? articles.filter((element) => element.article_categories.some((subElement) => subElement.urlPart === "cremation" || subElement.urlPart === "funeral-cost")) : articles

  return (
    <div
      className={props.gridClass ? props.gridClass : "articles-section pr-4 pl-4"}
    >
      <div className="block-wrapper">
        <div className="row no-gutters" style={stylesInline.minHeight}>
          <div className="col-12 col-lg-8 mb-0 pb-0 pb-lg-5">
            <h2 className="mb-3 mb-xl-0 text-white pl-0">{props.sectionTitle ? props.sectionTitle : "Articles"}</h2>
            {props.sectionText && <ReadMore
              text={props.sectionText}
              maxLength={2}
              className="col-content text-white text-18"
            />}
          </div>
        </div>
        <div className="row no-gutters justify-content-between position-relative articles-grid">
          {articlesList.slice(0, 5).map(function (item, i) {
            const articleShortText = item.shortContent && item.shortContent?.data?.shortContent && (
              <div>
                {JSON.parse(
                  item.shortContent.data.shortContent
                ).blocks.map((block, idx) =>
                  CleanDataParser(block, idx)
                )}
              </div>
            );
            const coverImage = item.coverImage ? item.coverImage.localFile.url : null;
            const itemSlug = item.parentCategorySlug !== null ? "/guidance/" + item.parentCategorySlug + "/" + item.urlPart + "/" : "/guidance/" + item.urlPart + "/";
            return (
              <div
                key={i}
                className={firstItem.indexOf(i) !== -1
                  ? "col-12 col-md-6 col-xl-8 article-col"
                  : "col-12 col-md-6 col-xl-4 article-col"}
              >
                <Link
                  to={itemSlug}
                  state={{data: item, articles: articles}}
                  className="follow-link d-block text-darkblue"
                >
                  <div
                    className={firstItem.indexOf(i) !== -1
                      ? "article-wrapper article-wrapper--horizontal d-flex flex-column flex-xl-row bg-white"
                      : "article-wrapper article-wrapper--vertical d-flex flex-column bg-white"}>
                    {coverImage ? (
                      <GatsbyImage
                        style={{height: "100%", overflow: "visible"}}
                        image={item.coverImage.localFile.childImageSharp.gatsbyImageData}
                        alt={item.titleShort ? item.titleShort : item.title}
                        objectPosition={"center top"}
                        loading="lazy"
                      />
                    ) : null}
                    <div className="article-content d-flex flex-column justify-content-between">
                      <div className="text-deepblue">
                        <h2
                          className="text-deepblue article-content__title mb-4">{item.titleShort ? item.titleShort : item.title}</h2>
                        <div className="article-content__text mt-3">{articleShortText}</div>
                      </div>
                      <u>Read more</u>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  minHeight: {
    minHeight: "unset",
  },
};