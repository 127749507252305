import * as React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage, withArtDirection} from "gatsby-plugin-image";

export default function LegalPackageGrid(props: any) {
  const serviceData =
  useStaticQuery(graphql`
      query {
          willPageData: strapiWill {
              title
              price
              priceCurrency
              widget {
                  subtitle
                  linkTo
                  includedService {
                      title
                  }
                  imageRegular {
                      localFile {
                          childImageSharp {
                              gatsbyImageData(placeholder: NONE)
                          }
                      }
                  }
                  imageMobile {
                      localFile {
                          childImageSharp {
                              gatsbyImageData(placeholder: NONE)
                          }
                      }
                  }
              }
          }
          lpaPageData: strapiLastingPowersOfAttorney {
              title
              price
              priceCurrency
              widget {
                  subtitle
                  linkTo
                  includedService {
                      title
                  }
                  imageRegular {
                      localFile {
                          childImageSharp {
                              gatsbyImageData(placeholder: NONE)
                          }
                      }
                  }
                  imageMobile {
                      localFile {
                          childImageSharp {
                              gatsbyImageData(placeholder: NONE)
                          }
                      }
                  }
              }
          }
      }
  `);

  const lpaImages = withArtDirection(getImage(serviceData.lpaPageData?.widget?.imageRegular?.localFile), [
    {
      media: "(max-width: 719px)",
      image: getImage(serviceData.lpaPageData?.widget?.imageMobile?.localFile),
    },
  ])
  const willsImages = withArtDirection(getImage(serviceData.willPageData?.widget?.imageRegular?.localFile), [
    {
      media: "(max-width: 719px)",
      image: getImage(serviceData.willPageData?.widget?.imageMobile?.localFile),
    },
  ])

  return (
    <div className="block-wrapper">
      <div className="row no-gutters" style={stylesInline.minHeight}>
        <div className="col-12"><h2 className="text-white">Our offers</h2></div>
      </div>
      <div className="row no-gutters package-row d-flex flex-wrap justify-content-between">
        <div className="col-12 col-md-6 col-lg-8 col-horizontal package-col">
          <div className="package-card bg-white">
            <Link to={serviceData.lpaPageData?.widget.linkTo} className="d-block d-lg-flex">
              <GatsbyImage className="package-card__image" image={lpaImages}
                           alt={"Lasting power of attorney Fenix Funeral Directors"}/>
              <div className="package-card__text d-block d-lg-flex flex-lg-column justify-content-lg-between">
                <div className="package-card__header">
                  <div className="package-card__title">{serviceData.lpaPageData?.title}</div>
                  <div className="package-card__description">{serviceData.lpaPageData?.widget?.subtitle}</div>
                </div>
                <div className="package-card__footer">
                  <div className="package-card__link">Read more</div>
                  <div
                    className="package-card__price">from <br/><span>{serviceData.lpaPageData?.priceCurrency + " " + serviceData.lpaPageData?.price}</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 package-col">
          <div className="package-card bg-white">
            <Link to={serviceData.willPageData?.widget.linkTo} className="d-block">
              <GatsbyImage className="package-card__image" image={willsImages} alt={"Wills Fenix Funeral Directors"}/>
              <div className="package-card__text d-block d-lg-flex flex-lg-column justify-content-lg-between">
                <div className="package-card__header">
                  <div className="package-card__title">{serviceData.willPageData?.title}</div>
                  <div className="package-card__description">{serviceData.willPageData?.widget?.subtitle}</div>
                </div>
                <div className="package-card__footer">
                  <div className="package-card__link">Read more</div>
                  <div
                    className="package-card__price">from <br/><span>{serviceData.willPageData?.priceCurrency + " " + serviceData.willPageData?.price}</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
const stylesInline: { [key: string]: React.CSSProperties } = {
  minHeight: {
    minHeight: "unset",
  },
};