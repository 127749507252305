import * as React from "react";
import {useElementSize} from 'usehooks-ts'

export default function Section(props: any) {
  const [obituaryRefMobile, sizeMobile] = useElementSize();
  props.hasOwnProperty("setSize") && props.setSize(sizeMobile);

  return (
    <div id={props.sectionId ? props.sectionId : ""}
         className={props.sectionClass ? props.sectionClass : "bg-deepblue pl-4 pr-4 section-container"}
         ref={obituaryRefMobile}
    >
      {props.children}
    </div>
  );
}